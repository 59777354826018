import React from 'react';
import Menu from '@material-ui/core/Menu';

const processUrl = (tool) => {
    const location = window.location.href;
    if (tool === 'reporting') {
        return 'https://addaiplatform.com/report';
    } else if (tool === 'welcome-page') {
        return 'https://addaiplatform.com/';
    }
    if (location.includes('dev')) {
        return 'https://' + tool + '.dev.addaiplatform.com';
    }
    if (location.includes('testing') || location.includes('localhost')) {
        return 'https://' + tool + '.test.addaiplatform.com';
    } else {
        return 'https://' + tool + '.addaiplatform.com';
    }
};

const appsData = [
    { name: 'Welcome Page', imgSrc: '/magic-ui.png', href: processUrl('welcome-page') },
    { name: 'Magic UI', imgSrc: '/magic-ui.png', href: processUrl('magic') },
    { name: 'Training Tool', imgSrc: '/training-tool.png', href: processUrl('training') },
    { name: 'Testing Tool', imgSrc: '/testing-tool.png', href: processUrl('testing') },
    { name: 'Reporting', imgSrc: '/reporting-tool.png', href: processUrl('reporting') }
];

const AppsDropdown = ({ appsAnchorEl, appsDropdownOpen, closeAppsDropdown }) => {
    return (
        <Menu
            anchorEl={appsAnchorEl}
            open={appsDropdownOpen}
            onClose={closeAppsDropdown}
            className="app-menu"
            PaperProps={{
                style: {
                    top: '0px',
                    right: '0px',
                    left: 'initial',
                    borderRadius: '8px',
                    padding: '0.75rem',
                    boxShadow: '0 3px 10px rgb(0 0 0 / 0.1)'
                },
            }}
        >
            <div className="grid grid-cols-2 gap-1.5 h-full">
                {appsData.map((app) => (
                    <a
                        key={app.name}
                        href={app.href}
                        className="text-black aspect-square hover:text-black hover:no-underline cursor-pointer w-full text-center flex flex-col items-center gap-2 rounded-lg p-1 hover:bg-[#f7F7F7]"
                    >
                        <div className="flex flex-col items-center m-auto gap-1.5">
                            <img alt="app icon" className="p-3 rounded-lg bg-[#3D9123]" width={74} height={24}
                                 src={app.imgSrc} />
                            <span className="whitespace-nowrap">{app.name}</span>
                        </div>
                    </a>
                ))}
            </div>
        </Menu>
    );
};

export default AppsDropdown;